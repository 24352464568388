const PublicationsData = [
  {
    authors:
      "Ran Zhang, Jihed Ouni and Steffen Eger",
    title: "Cross-lingual Cross-temporal Summarization: Dataset, Models, Evaluation",
    venue: '"Computational Linguistics" (CL), 2024',
    paper: "https://arxiv.org/abs/2306.12916",
    abstract: (
      <p>
        While summarization has been extensively researched in natural language processing (NLP), 
        cross-lingual cross-temporal summarization (CLCTS) is a largely unexplored area that has 
        the potential to improve cross-cultural accessibility and understanding. This paper comprehensively 
        addresses the CLCTS task, including dataset creation, modeling, and evaluation. We build 
        the first CLCTS corpus, leveraging historical fictive texts and Wikipedia summaries in 
        English and German, and examine the effectiveness of popular transformer end-to-end models 
        with different intermediate finetuning tasks. Additionally, we explore the potential of 
        ChatGPT for CLCTS as a summarizer and an evaluator. Overall, we report evaluations from 
        humans, ChatGPT, and several recent automatic evaluation metrics where we find that our 
        intermediate task finetuned end-to-end models generate bad to moderate quality summaries; 
        ChatGPT as a summarizer (without any finetuning) provides moderate to good quality outputs 
        and as an evaluator correlates moderately with human evaluations but is prone to giving 
        lower scores. ChatGPT also seems very adept at normalizing historical text and outperforms 
        context-unaware spelling normalization tools such as Norma. We finally test ChatGPT in a 
        scenario with adversarially attacked and unseen source documents and find that ChatGPT profits 
        from its prior knowledge to a certain degree, with better performances for omission and entity 
        swap than negation against its prior knowledge. This benefit inflates its assessed quality as 
        ChatGPT performs slightly worse for unseen source documents compared to seen documents. We 
        additionally introspect our models' performances to find that longer, older and more complex 
        source texts (all of which are more characteristic for historical language variants) are 
        harder to summarize for all models, indicating the difficulty of the CLCTS task. 
      </p>
    ),
  },
  {
    authors:
      "Christoph Leiter, Ran Zhang, Yanran Chen, Jonas Belouadi, Daniil Larionov, Vivian Fresen, Steffen Eger",
    title: "ChatGPT: A Meta-Analysis after 2.5 Months",
    venue: '"Machine Learning with Applications" (MLWA), 2024',
    paper: "https://arxiv.org/abs/2302.13795",
    abstract: (
      <p>
        ChatGPT, a chatbot developed by OpenAI, has gained widespread popularity
        and media attention since its release in November 2022. However, little
        hard evidence is available regarding its perception in various sources.
        In this paper, we analyze over 300,000 tweets and more than 150
        scientific papers to investigate how ChatGPT is perceived and discussed.
        Our findings show that ChatGPT is generally viewed as of high quality,
        with positive sentiment and emotions of joy dominating in social media.
        Its perception has slightly decreased since its debut, however, with joy
        decreasing and (negative) surprise on the rise, and it is perceived more
        negatively in languages other than English. In recent scientific papers,
        ChatGPT is characterized as a great opportunity across various fields
        including the medical domain, but also as a threat concerning ethics and
        receives mixed assessments for education. Our comprehensive
        meta-analysis of ChatGPT's current perception after 2.5 months since its
        release can contribute to shaping the public debate and informing its
        future development. We make our data available.
      </p>
    ),
  },
  {
    authors: "Gil Rocha, Henrique Lopes Cardoso, Jonas Belouadi, Steffen Eger",
    title:
      "Cross-Genre Argument Mining: Can Language Models Automatically Fill in Missing Discourse Markers?",
    venue: 'Journal of "Argumentation & Computation"',
    paper: "https://arxiv.org/abs/2306.04314",
    abstract: (
      <p>
        Available corpora for Argument Mining differ along several axes, and one
        of the key differences is the presence (or absence) of discourse markers
        to signal argumentative content. Exploring effective ways to use
        discourse markers has received wide attention in various discourse
        parsing tasks, from which it is well-known that discourse markers are
        strong indicators of discourse relations. To improve the robustness of
        Argument Mining systems across different genres, we propose to
        automatically augment a given text with discourse markers such that all
        relations are explicitly signaled. Our analysis unveils that popular
        language models taken out-of-the-box fail on this task; however, when
        fine-tuned on a new heterogeneous dataset that we construct (including
        synthetic and real examples), they perform considerably better. We
        demonstrate the impact of our approach on an Argument Mining downstream
        task, evaluated on different corpora, showing that language models can
        be trained to automatically fill in discourse markers across different
        corpora, improving the performance of a downstream model in some, but
        not all, cases. Our proposed approach can further be employed as an
        assistive tool for better discourse understanding.{" "}
      </p>
    ),
  },
  {
    authors:
      "Christoph Leiter, Piyawat Lertvittayakumjorn, M. Fomicheva, Wei Zhao, Yang Gao, Steffen Eger",
    title: "Towards Explainable Evaluation Metrics for Machine Translation",
    venue: '"Journal of Machine Learning Research" (JMLR), 2024',
    paper: "https://jmlr.org/papers/v25/22-0416.html",
    abstract: (
      <p>
        Unlike classical lexical overlap metrics such as BLEU, most current
        evaluation metrics for machine translation (for example, COMET or
        BERTScore) are based on black-box large language models. They often
        achieve strong correlations with human judgments, but recent research
        indicates that the lower-quality classical metrics remain dominant, one
        of the potential reasons being that their decision processes are more
        transparent. To foster more widespread acceptance of novel high-quality
        metrics, explainability thus becomes crucial. In this concept paper, we
        identify key properties as well as key goals of explainable machine
        translation metrics and provide a comprehensive synthesis of recent
        techniques, relating them to our established goals and properties. In
        this context, we also discuss the latest state-of-the-art approaches to
        explainable metrics based on generative models such as ChatGPT and GPT4.
        Finally, we contribute a vision of next-generation approaches, including
        natural language explanations. We hope that our work can help catalyze
        and guide future research on explainable evaluation metrics and,
        mediately, also contribute to better and more transparent machine
        translation systems.
      </p>
    ),
  },
  {
    authors: "Jonas Belouadi, Steffen Eger",
    title:
      "AutomaTikZ: Text-Guided Synthesis of Scientific Vector Graphics with TikZ",
    venue: "ICLR, 2024",
    paper: "https://arxiv.org/abs/2310.00367",
    code: "https://github.com/potamides/AutomaTikZ",
    abstract: (
      <p>
        Generating bitmap graphics from text has gained considerable attention,
        yet for scientific figures, vector graphics are often preferred. Given
        that vector graphics are typically encoded using low-level graphics
        primitives, generating them directly is difficult. To address this, we
        propose the use of TikZ, a well-known abstract graphics language that
        can be compiled to vector graphics, as an intermediate representation of
        scientific figures. TikZ offers human-oriented, high-level commands,
        thereby facilitating conditional language modeling with any large
        language model. To this end, we introduce DaTikZ, the first large-scale
        TikZ dataset consisting of 120k TikZ drawings aligned with captions. We
        fine-tune LLaMA on DaTikZ, as well as our new model CLiMA, which
        augments LLaMA with multimodal CLIP embeddings. In both human and
        automatic evaluation, CLiMA and LLaMA outperform commercial GPT-4 and
        Claude 2 in terms of similarity to human-created figures, with CLiMA
        additionally improving text-image alignment. Our detailed analysis shows
        that all models generalize well and are not susceptible to memorization.
        GPT-4 and Claude 2, however, tend to generate more simplistic figures
        compared to both humans and our models. We make our framework,
        AutomaTikZ, along with model weights and datasets, publicly available.
      </p>
    ),
  },
  {
    authors: "Christoph Leiter, Hoa Nguyen, Steffen Eger",
    title: "BMX: Boosting Machine Translation Metrics with Explainability",
    venue: "EACL, 2024",
    paper: "https://arxiv.org/abs/2212.10469",
    code: "https://github.com/Gringham/BMX",
  },
  {
    authors: "Daniil Larionov, Jens Grünwald, Christoph Leiter, Steffen Eger",
    title:
      "EffEval: A Comprehensive Evaluation of Efficiency for MT Evaluation Metrics",
    venue: "EMNLP, 2023",
    paper: "https://arxiv.org/abs/2209.09593",
    code: "https://github.com/NL2G/effeval",
  },
  {
    authors: "Christoph Leiter, Steffen Eger",
    title:
      "The Eval4NLP 2023 Shared Task on Prompting Large Language Models as Explainable Metrics",
    venue: "IJCNLP-AACL, 2023",
    paper: "https://arxiv.org/abs/2310.19792",
    code: "https://github.com/eval4nlp/SharedTask2023/tree/main",
  },
  {
    authors: "Jonas Belouadi, Steffen Eger",
    title:
      "ByGPT5: End-to-End Style-conditioned Poetry Generation with Token-free Language Models",
    venue: "ACL, 2023",
    paper: "https://aclanthology.org/2023.acl-long.406/",
    code: "https://github.com/potamides/uniformers",
  },
  {
    authors: "Yanran Chen, Steffen Eger",
    title: "MENLI: Robust Evaluation Metrics from Natural Language Inference",
    venue: "TACL, 2023",
    paper:
      "https://direct.mit.edu/tacl/article/doi/10.1162/tacl_a_00576/116715/MENLI-Robust-Evaluation-Metrics-from-Natural",
    code: "https://github.com/cyr19/MENLI",
  },
  {
    authors: "Jonas Belouadi, Steffen Eger",
    title:
      "USCORE: An Effective Approach to Fully Unsupervised Evaluation Metrics for Machine Translation",
    venue: "EACL, 2023",
    paper: "https://arxiv.org/abs/2202.10062",
    code: "https://github.com/potamides/unsupervised-metrics",
  },
  {
    authors: "Wei Zhao, Michael Strube, Steffen Eger",
    title:
      "DiscoScore: Evaluating Text Generation with BERT and Discourse Coherence",
    venue: "EACL, 2023",
    paper: "https://arxiv.org/abs/2201.11176",
    code: "https://github.com/AIPHES/DiscoScore",
  },
  {
    authors: "Yanran Chen, Jonas Belouadi, Steffen Eger",
    title: "Reproducibility Issues for BERT-based Evaluation Metrics",
    venue: "EMNLP, 2022",
    paper: "https://arxiv.org/abs/2204.00004",
    code: "https://github.com/cyr19/Reproducibility",
  },
  {
    authors: "Doan Nam Long Vu, Nafise Sadat Moosavi, Steffen Eger",
    title:
      "Layer or Representation Space: What makes BERT-based Evaluation Metrics Robust?",
    venue: "COLING 2022",
    paper: "https://arxiv.org/abs/2209.02317",
  },
  {
    authors: "Steffen Eger, Dan Liu, Daniela Grunow",
    title:
      "Measuring Social Solidarity During Crisis: The Role of Design Choices",
    venue: "Journal of Social Computing, 2022",
    paper: "https://ieeexplore.ieee.org/abstract/document/9832842",
  },
  {
    authors: "Wei Zhao, Steffen Eger",
    title:
      "Constrained Density Matching and Modeling for Cross-lingual Alignment of Contextualized Representations",
    venue: "ACML 2022",
    paper: "https://arxiv.org/abs/2201.13429",
  },
  {
    authors: "Marvin Kaster, Wei Zhao, Steffen Eger",
    title:
      "Global Explainability of BERT-based Evaluation Metrics by Disentangling along Linguistic Factors",
    venue: "EMNLP, 2021",
    paper: "https://arxiv.org/pdf/2110.04399.pdf",
    abstract: (
      <p>
        Evaluation metrics are a key ingredient for progress of text generation
        systems. In recent years, several BERT-based evaluation metrics have
        been proposed (including BERTScore, MoverScore, BLEURT, etc.) which
        correlate much better with human assessment of text generation quality
        than BLEU or ROUGE, invented two decades ago. However, little is known
        what these metrics, which are based on black-box language model
        representations, actually capture (it is typically assumed they model
        semantic similarity). In this work, we use a simple regression based
        global explainability technique to disentangle metric scores along
        linguistic factors, including semantics, syntax, morphology, and lexical
        overlap. We show that the different metrics capture all aspects to some
        degree, but that they are all substantially sensitive to lexical
        overlap, just like BLEU and ROUGE. This exposes limitations of these
        novelly proposed metrics, which we also highlight in an adversarial test
        scenario.
      </p>
    ),
  },
  {
    authors:
      "Marina Fomicheva, Piyawat Lertvittayakumjorn, Wei Zhao, Steffen Eger, Yang Gao",
    title:
      "The Eval4NLP Shared Task on Explainable Quality Estimation: Overview and Results",
    venue: "Eval4NLP, 2021",
    paper: "https://arxiv.org/abs/2110.04392",
    abstract: (
      <p>
        In this paper, we introduce the Eval4NLP-2021 shared task on explainable
        quality estimation. Given a source-translation pair, this shared task
        requires not only to provide a sentencelevel score indicating the
        overall quality of the translation, but also to explain this score by
        identifying the words that negatively impact translation quality. We
        present the data, annotation guidelines and evaluation setup of the
        shared task, describe the six participating systems, and analyze the
        results. To the best of our knowledge, this is the first shared task on
        explainable NLP evaluation metrics. Datasets and results are available
        at{" "}
        <a
          href="https://github.
com/eval4nlp/SharedTask2021"
        >
          https://github. com/eval4nlp/SharedTask2021
        </a>
      </p>
    ),
  },
];

export default PublicationsData;
