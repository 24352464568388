const ThesesData = [
  {
    "type": "Bachelor/Master",
    "content": "Open 2024",
    "title": "Can LLMs evaluate themselves?",
    "description": (
      <div>
        <p>
          LLMs are everywhere in recent NLP research. In particular, LLMs such
          as GEMBA [1] or GPTScore [2] evaluate other LLMs such as machine
          translation models or summarization models built on LLMs. This raises
          the question of circularity: is it really possible for a model to
          evaluate itself adequately? Is there a bias when the same model
          evaluates itself - e.g., do they prefer their own outputs [3]? How
          does this relate to the concept of self-refinement [4]?
        </p>
        <p>
          <b>Task:</b> In this thesis, we explore to which degree self-evaluation
          is possible and how this depends on the models involved (e.g., a
          smaller evaluation model evaluating a larger text generation model and
          vice versa). What are the limits of self-evaluation?
        </p>
        <p>
          <b>Supervisor:</b> Steffen Eger, steffen.eger@uni-mannheim.de
        </p>
        <div>
          <b>Literature:</b>
          <ul>
            <li key="uniqueId3">
              <a href="https://arxiv.org/pdf/2302.14520.pdf">
                [1] https://arxiv.org/pdf/2302.14520.pdf
              </a>
            </li>
            <li key="uniqueId4">
              <a href="https://arxiv.org/abs/2302.04166">
                [2] https://arxiv.org/abs/2302.04166
              </a>
            </li>
            <li key="uniqueId5">
              <a href="https://arxiv.org/abs/2210.12563 ">
                [3] https://arxiv.org/abs/2210.12563
              </a>
            </li>
            <li key="uniqueId6">
              <a href="https://arxiv.org/abs/2303.17651 ">
                [4] https://arxiv.org/abs/2303.17651
              </a>
            </li>
          </ul>
        </div>
        <p>
          <b>Keywords:</b> Evaluation, LLMs, Evaluation Metrics
        </p>
      </div>
    ),
  },
  {
    "type": "Bachelor/Master",
    "content": "Open 2024",
    "title": "Low-resource & efficient text generation evaluation",
    "description": (
      <div>
        <p>
          {" "}
          Evaluation metrics for text generation [1] usually work better on
          high-resource language pairs and with large amounts of data on which
          to train them [2,3]. This raises the question of how metrics perform
          with limited amounts of training data and how they can be improved
          with techniques such as active learning [4].
        </p>
        <p>
          <b>Task:</b> In this thesis, we explore how metrics (e.g., in MT)
          perform on lower-resource languages (e.g., Yoruba-German) and with
          smaller amounts of supervision (as would be the case for
          lower-resource languages). To do so, we will explore active learning
          to annotate more important data instances as well as other strategies
          to obtain better metrics in such settings [5,6].
        </p>
        <p>
          <b>Supervisor:</b> Steffen Eger, steffen.eger@uni-mannheim.de
        </p>
        <div>
          <b>Literature:</b>
          <ul>
            <li key="uniqueId7">
              <a href="https://arxiv.org/pdf/2302.14520.pdf">
                [1] https://arxiv.org/pdf/2302.14520.pdf
              </a>
            </li>
            <li key="uniqueId8">
              <a href="https://unbabel.github.io/COMET/html/index.html ">
                [2] https://unbabel.github.io/COMET/html/index.html
              </a>
            </li>
            <li key="uniqueId9">
              <a href="https://aclanthology.org/2022.acl-long.558/ ">
                [3] https://aclanthology.org/2022.acl-long.558/
              </a>
            </li>
            <li key="uniqueId10">
              <a href="https://aclanthology.org/2022.emnlp-demos.41/ ">
                [4] https://aclanthology.org/2022.emnlp-demos.41/
              </a>
            </li>
            <li key="uniqueId11">
              <a href="https://aclanthology.org/2023.eacl-main.27/">
                [5] https://aclanthology.org/2023.eacl-main.27/
              </a>
            </li>
            <li key="uniqueId12">
              <a href="https://aclanthology.org/2023.eacl-main.95.pdf ">
                [6] https://aclanthology.org/2023.eacl-main.95.pdf
              </a>
            </li>
          </ul>
        </div>
        <p>
          <b>Keywords:</b> Evaluation, LLMs, Evaluation Metrics, Active
          Learning, Low-Resource Trainign, Diversity, Inclusion
        </p>
      </div>
    ),
  },
  {
    "type": "Bachelor/Master",
    "content": "Open 2024",
    "title": "On the fundamental limitations of Large Language Models",
    "description": (
      <div>
        <p>
          {" "}
          LLMs have yielded spectacular results in many use cases, often
          rivaling humans [1]. On the other hand, they also still have severe
          limitations regarding multiple aspects, e.g., various forms of
          reasoning, multilinguality, etc. [2,3,4]. However, it is unclear how
          limitations affect models of different sizes, and which limitations
          have already been addressed, given the fast pace of LLMs.
        </p>
        <p>
          <b>Task:</b> This thesis provides an encompassing survey of LLLMs -
          Limitations of LLMs. It also provides benchmarks (e.g., by merging
          different existing benchmarks) on which to evaluate limitations of
          LLMs and investigates to which degree smaller and more recent models
          are susceptible to limitations.
        </p>
        <p>
          <b>Supervisor:</b> Steffen Eger, steffen.eger@uni-mannheim.de
        </p>
        <div>
          <b>Literature:</b>
          <ul>
            <li key="uniqueId13">
              <a href="https://arxiv.org/abs/2303.12712">
                [1] https://arxiv.org/abs/2303.12712
              </a>
            </li>
            <li key="uniqueId14">
              <a href="https://arxiv.org/abs/2309.01219 ">
                [2] https://arxiv.org/abs/2309.01219
              </a>
            </li>
            <li key="uniqueId15">
              <a href="https://arxiv.org/abs/2302.03494 ">
                [3] https://arxiv.org/abs/2302.03494
              </a>
            </li>
            <li key="uniqueId16">
              <a href="https://arxiv.org/abs/2305.16339 ">
                [4] https://arxiv.org/abs/2305.16339
              </a>
            </li>
          </ul>
        </div>
        <p>
          <b>Keywords:</b> LLLMs
        </p>
      </div>
    ),
  },
  {
    "type": "Bachelor/Master",
    "content": "Open 2024",
    "title": "Is gender bias a one-way street?",
    "description": (
      <div>
        <p>
          A lot of research has been devoted to determining and eliminating
          (gender) bias in word embeddings or text generation models [1,2].
          However, most research assumes (and finds) that gender bias is a
          one-way street in which female actors face discrimination while male
          factors are advantaged. While females are indeed historically
          marginalized, there are several instances where females are seemingly
          at an advantage in the modern world, e.g., there is arguably a
          stronger bias against male refugees, a stronger bias against males as
          perpetrators/criminals, and a bias against males, e.g., in university
          recruiting
        </p>
        <p>
          <b>Task:</b> In this thesis, we challenge the assumption of gender
          bias as a one-way street and investigate cases where males are
          disadvantaged (if they are indeed). This contributes to a fairer
          discussion of gender bias.
        </p>
        <p>
          <b>Supervisor:</b> Steffen Eger, steffen.eger@uni-mannheim.de
        </p>
        <div>
          <b>Literature:</b>
          <ul>
            <li key="uniqueId1">
              <a href="https://aclanthology.org/P19-1159/">
                [1] https://aclanthology.org/P19-1159/
              </a>
            </li>
            <li key="uniqueId2">
              <a href="https://proceedings.neurips.cc/paper_files/paper/2016/file/a486cd07e4ac3d270571622f4f316ec5-Paper.pdf">
                [2]
                https://proceedings.neurips.cc/paper_files/paper/2016/file/a486cd07e4ac3d270571622f4f316ec5-Paper.pdf
              </a>
            </li>
          </ul>
        </div>
        <p>
          <b>Keywords:</b> NLP, Social Science, Bias
        </p>
      </div>
    ),
  },
  {
    "type": "Bachelor/Master",
    "content": "Open 2024",
    "title": "LLM for literary translation and evaluation",
    "description": (
      <div>
        <p>
          Large language models (LLMs) demonstrate remarkable performance on
          both sentence-level [1] and paragraph-level [2-3] translation.
          However, the capability of LLMs for literary translation, especially
          historical literature, remains largely unexplored [4]. Can other LLMs
          such as LaMMA 2 or Mamba perform equally as well as GPT-3.5 or GPT-4?
          How well can they perform for low-resource languages [5] or distant
          language pairs (e.g., Chinese-German)? How well are existing
          evaluation metrics or can LLMs evaluate literary translation to
          replace expensive human annotators [6]?
        </p>
        <p>
          <b>Task:</b> In this thesis, we explore LLMs for literary translation
          by 1) providing benchmarks for multiple LLMs at the sentence level or
          paragraph level through prompt exploration; 2) experimenting with
          existing automatic metrics for literary machine translation
          evaluation. Additionally, one can also explore more languages,
          especially low-resource or distant languages.
        </p>
        <p>
          <b>Supervisors:</b> Steffen Eger, steffen.eger@uni-mannheim.de; Ran
          Zhang, ran.zhang@uni-mannheim.de
        </p>
        <div>
          <b>Literature:</b>
          <ul>
            <li key="uniqueId17">
              <a href="https://arxiv.org/abs/2301.08745">
                [1] https://arxiv.org/abs/2301.08745
              </a>
            </li>
            <li key="uniqueId18">
              <a href="https://arxiv.org/pdf/2304.03245">
                [2] https://arxiv.org/pdf/2304.03245
              </a>
            </li>
            <li key="uniqueId19">
              <a href="https://arxiv.org/abs/2304.02210">
                [3] https://arxiv.org/abs/2304.02210
              </a>
            </li>
            <li key="uniqueId20">
              <a href="https://arxiv.org/pdf/2210.14250">
                [4] https://arxiv.org/pdf/2210.14250
              </a>
            </li>
            <li key="uniqueId21">
              <a href="https://aclanthology.org/2022.tacl-1.30/">
                [5] https://aclanthology.org/2022.tacl-1.30/
              </a>
            </li>
            <li key="uniqueId22">
              <a href="https://arxiv.org/abs/2303.13809">
                [6] https://arxiv.org/abs/2303.13809
              </a>
            </li>
          </ul>
        </div>
        <p>
          <b>Keywords:</b> Literary Translation, LLMs, Translation Evaluation
        </p>
      </div>
    ),
  },
];

export default ThesesData;
