import PublicationList from "../components/display/PublicationList";
import PageHeader from "../components/display/PageHeader";
import PreprintData from "../data/content/PreprintData";
import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";

function PublicationPage() {
  return (
    <Container className={classes.container}>
      <PageHeader
        headerText="Preprints"
        headerImage={require("../data/content/images/publications1.png")}
        headerImageAlt="Preprints"
        headerDescription="Selected recent preprints."
      />
      <PublicationList publicationData={PreprintData} />
    </Container>
  );
}

export default PublicationPage;
