import TeachingList from "../components/display/TeachingList";
import TeachingData from "../data/content/TeachingData";
import PageHeader from "../components/display/PageHeader";
import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";

function TeachingPage() {
  return (
    <Container className={classes.container}>
      <PageHeader
        headerText="Teaching"
        headerImage={require("../data/content/images/teaching1.png")}
        headerImageAlt="Teaching"
        headerDescription="Recent courses:"
      />
      <TeachingList teachingData={TeachingData} />
    </Container>
  );
}

export default TeachingPage;
