const ProjectData = [
   {
    title: "EMCONA",
    description: (
      <p>
      In the DFG project EMCONA, PIs Roman Klinger and Steffen Eger investigate the interplay between emotions, measured via appraisal, and convincingness in argument mining. An emphasis will be on ethical bias-free generation of convincing argumentation without emotional fallacies. The share of Steffen Eger is 337,816 EUR.
	    </p>
    ),
    date: "2023-2026",
	  volume: "720,000€"
  },

   {
    title: "Metrics4NLG",
    description: (
      <p>
	    Metrics4NLG is an interdisciplinary project. It involves the design of
        novel evaluation metrics for Natural Language Generation tasks such as
        summarization and machine translation, which are efficient, explainable,
        robust, etc. Metrics4NLG is also concerned with humanities applications,
        in particular poetry generation and evaluation as well as the diachronic
        and diatopic dimension of language. Metrics4NLG is funded by the BMBF,
        with a volume of 934,311.60 EUR (3 PhD positions). More details on the
        BMBF format for the grant can be found{" "}
        <a href="https://www.bmbf.de/bmbf/shareddocs/bekanntmachungen/de/2021/05/3594_bekanntmachung.html">
          here
        </a>
        .
      </p>
    ),
    date: "2022-2025",
    volume: "934,311.60€",
	  image : require("./images/Metrics4NLG.png")
  },
  {
    title:
      "DFG Heisenberg Fellowship: Cross-lingual Cross-temporal Evaluation in NLP and the Digital Humanities",
    description: (
      <p>
        This grant finances Steffen Eger's research of the next few years on the
        topics of language variation across time and space, cross-lingual and
        cross-temporal evaluation (metrics) and cross-temporal analysis at the
        intersection of social science, digital humanities and natural language
        processing. The grant is funded by the DFG, with a volume of 587,500
        EUR.
      </p>
    ),
    date: "2022-2027",
	  volume:"587,500€"
  },
  {
    title: "Eval4NLP Shared Task on Explainable Metrics",
    description: (
      <p>
        This grant by the AI Journal allowed my collaborators and me to conduct
        annotation studies on explainable evaluation metrics for machine
        translation as well as organize a shared task (and write a{" "}
        <a href="https://aclanthology.org/2021.eval4nlp-1.17/">paper</a> on it).
        The{" "}
        <a href="https://aij.ijcai.org/wp-content/uploads/2021/07/AIJ-23-allocation-aij.pdf">
          grant
        </a>{" "}
        was funded by the <a href="https://aij.ijcai.org/">AI Journal</a>, with
        a volume of 4,500 EUR.
      </p>
    ),
    date: "2021",
    volume: "4500€",
    image: require("./images/dummy.jpg"),
  },
  {
    title:
      "Doppelt annotierte Korpora: 'Natur und Staat' und Reichtstagsprotokolle für die innovative automatische Metaphernanalyse",
    description: (
      <p>
        This grant allowed me and collaborator (philosopher) Petra Gehring to
        study metaphors in historical German texts at the start of the 20th
        century (Darwinist texts and parliamentary proceedings). To do so, we
        used an innovative theory of metaphors, the so-called Kontextbruch
        model. The{" "}
        <a href="https://www.fif.tu-darmstadt.de/foerderung/foerderung_details_19584.de.jsp">
          grant
        </a>{" "}
        was funded by the <a href="https://www.fif.tu-darmstadt.de/">FIF</a>, an
        interdisciplinary research forum at TU Darmstadt, with a volume of
        52,000 EUR.
      </p>
    ),
    date: "2020",
  },
];

export default ProjectData;
