import { Link } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import classes from './NLLGNavbar.module.css';

function NLLGNavbar(props) {
  return (
    <header>
      <Navbar id="nllg-navbar" bg="light" expand="xxl" variant="light">
        {props.logos.map( (logo) => (
          <Navbar.Brand href={logo[1]}>
              <img className={classes.resize} src={logo[0]} alt="site-logo" />
          </Navbar.Brand>
        ))}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/team">
              Team
            </Nav.Link>
            <Nav.Link as={Link} to="/publications">
              Publications
            </Nav.Link>
            <Nav.Link as={Link} to="/preprints">
              Preprints
            </Nav.Link>
            <Nav.Link as={Link} to="/theses">
              Theses
            </Nav.Link>
            <Nav.Link as={Link} to="/teaching">
              Teaching
            </Nav.Link>
            <Nav.Link as={Link} to="/projects">
              Projects
            </Nav.Link>
            <Nav.Link as={Link} to="/philosophy">
              Philosophy
            </Nav.Link>
            <Nav.Link as={Link} to="/positions">
              Open Positions
            </Nav.Link>
            <Nav.Link as={Link} to="/impressum">
              Impressum
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
  
}

// Add this to navbar to show that the page is under construction
//<div className={classes.construction}>
//  Page Under Construction!
//</div>

export default NLLGNavbar;
