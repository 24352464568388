import TeamMemberList from "../components/display/TeamMemberList";
import TeamData from "../data/content/TeamData";
import PageHeader from "../components/display/PageHeader";
import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";

function TeamPage() {
  return (
    <Container className={classes.container}>
      <PageHeader
        headerText="NLLG Team"
        headerImage={require("../data/content/images/team1.png")}
        headerImageAlt="Team"
        headerDescription="Introductions of our team members."
      />
      <TeamMemberList teamData={TeamData} />
    </Container>
  );
}

export default TeamPage;
