import Obfuscate from "react-obfuscate";

const TeamData = [
  [
    "Group Leader",
    {
      name: "Steffen Eger",
      image: require("./images/SteffenEger.jpg"),
      role: "Group Leader",
      description: (
        <div>
          <p>
            I am{" "}
            <a
              class="link"
              href="https://ekvv.uni-bielefeld.de/pers_publ/publ/PersonDetail.jsp?personId=346380567"
            >
              Heisenberg Group Leader
            </a>{" "}
            at University of Mannheim. Before that, I was Substitute Professor,
            substituting Philipp Cimiano in the Semantic Computing Group at
            Bielefeld University and
            <a
              class="link"
              href="https://www.informatik.tu-darmstadt.de/aiphes/aiphes/irg_position/index.en.jsp"
            >
              {" "}
              Independent Research Group Leader (IRGL)
            </a>{" "}
            ``NLP for the Humanities'' at TU Darmstadt. I obtained my PhD in
            economics on the topic of{" "}
            <a class="link" href="http://adiuvaris.org/main.pdf">
              Social Learning/Opinion Dynamics
            </a>
            .
          </p>
          <p>
            My research interests include a variety of topics at the
            intersection of Natural Language Processing (NLP), Machine Learning,
            and applications in the Digital Humanities and Social Sciences. For
            more information, see the{" "}
            <a href="https://nl2g.github.io/">group homepage</a> and my{" "}
            <a href="https://steffeneger.github.io/">personal homepage</a>.
          </p>
          <p>
            <strong>Email:</strong> steffen.eger(at)uni-mannheim.de <br />{" "}
            <a
              class="link"
              href="https://scholar.google.com/citations?user=TnuqAW0AAAAJ&hl=de&oi=ao"
            >
              Google Scholar
            </a>{" "}
            |{" "}
            <a class="link" href="https://github.com/SteffenEger">
              Github
            </a>{" "}
            |{" "}
            <a class="link" href="https://twitter.com/egere14">
              Twitter
            </a>
          </p>
        </div>
      ),
    },
  ],
  [
    "PhD Students",
    {
      name: "Jonas Belouadi",
      image: require("./images/JonasBelouadi.jpg"),
      role: "PhD Student",
      description: (
        <div>
          <p>
            Greetings! I'm a PhD student at Bielefeld University, still a
            work-in-progress, much like my research. Before starting my journey
            here, I received my master's degree with honors from TU Darmstadt,
            where I first discovered my passion for Machine Learning and Natural
            Language Processing (NLP).
          </p>
          <p>
            I'm particularly interested in Natural Language Generation (NLG)
            tasks and their evaluation. Machine Translation (MT), Creative
            Generation, and multi-modal NLG are some of the areas that fascinate
            me the most. Throughout my PhD program, I have had the opportunity
            to delve deeper into these interests by tackling challenging
            low-resource NLG problems. These include developing unsupervised
            evaluation metrics for MT, end-to-end style-conditioned poetry
            generation, and generating scientific graphics.
          </p>
          <p>
            Beyond the computational realm, I also have a love for classical
            philology and learning new languages. It's a great way to expand my
            own knowledge and alleviate the burden on NLP models.
          </p>
          <p>
            If you have any questions or would like to know more about my work,
            please don't hesitate to reach out. I'm always happy to connect with
            others who share my interests.
          </p>
          <p>
            <table>
              <tr>
                <th>Email:</th>
                <td>
                  <Obfuscate
                    class="link"
                    email="jonas.belouadi@uni-bielefeld.de"
                  />
                </td>
              </tr>
              <tr>
                <th>GitHub</th>
                <td>
                  <Obfuscate class="link" href="https://github.com/potamides">
                    github.com/potamides
                  </Obfuscate>
                </td>
              </tr>
              <tr>
                <th>LinkedIn:</th>
                <td>
                  <Obfuscate
                    class="link"
                    href="https://linkedin.com/in/belouadi"
                  >
                    linkedin.com/in/belouadi
                  </Obfuscate>
                </td>
              </tr>
            </table>
          </p>
        </div>
      ),
    },
    {
      name: "Christoph Leiter",
      image: require("./images/ChristophLeiter.jpg"),
      role: "PhD Student",
      description: (
        <div>
          <p>
            As a second-year PhD student at University of Mannheim, I am working
            with the NLLG group to research and create explainable and efficient
            text generation metrics. Through dual bachelor- and master- studies
            with Hewlett-Packard Enterprise and DXC Technology, as well as
            another year where I worked as a technical consultant, I got the
            opportunity to collect working experience in various fields,
            including Data Engineering, Data Science and Autonomous Driving. I
            received my masters degree with honors at the TU Darmstadt, where I
            pursued my key interests in Natural Language Processing, Deep
            Learning and Computer Vision. My thesis focused on exploring
            techniques for the explainability of machine translation evaluation
            metrics.
          </p>
          <p>
            I am especially interested in the application of Natural Language
            Generation techniques, metrics for generated language and their
            applicance to real world use cases. This particulary includes:
            <ul>
              <li key="uniqueId20">
                <b>Explainability</b>: Relying on metrics without knowing their
                internal workings might create a false impression of the data
                they are grading. Hence, real world applications require
                explainable metrics that can be understood by developers and
                users.
              </li>
              <li key="uniqueId21">
                <b>Efficiency</b>: Real world usecases of text generation
                sometimes require seamless execution times. Hence, it is
                interesting to explore mterics applicable to these scenarios.
              </li>
            </ul>
          </p>
          <p>Feel free to reach out to me!</p>
          <p>
            <table>
              <tr>
                <th>Email:</th>
                <td>
                  <Obfuscate
                    class="link"
                    email="christoph.leiter@uni-mannheim.de"
                  />
                </td>
              </tr>
              <tr>
                <th>GitHub</th>
                <td>
                  <Obfuscate class="link" href="https://github.com/Gringham">
                    github.com/Gringham
                  </Obfuscate>
                </td>
              </tr>
              <tr>
                <th>LinkedIn:</th>
                <td>
                  <Obfuscate
                    class="link"
                    href="https://linkedin.com/in/christoph-leiter-0883b515b"
                  >
                    linkedin.com/in/christoph-leiter-0883b515b
                  </Obfuscate>
                </td>
              </tr>
            </table>
          </p>
        </div>
      ),
    },
    {
      name: "Daniil Larionov",
      image: require("./images/DaniilLarionov.jpeg"),
      role: "PhD Student",
      email: "daniil.larionov@uni-mannheim.de",
      description: (
        <div>
          <p>
            I am a first-year Ph.D. student at University of Mannheim. Before
            that, I obtained my master's degree in Computational linguistics
            from HSE University, supervised by Dr. Artem Shelmanov. I've also
            worked at NLP Lab at FRC CSC RAS.
          </p>
          <p>
            I am interested in Natural Language Processing, Deep Learning,
            efficiency, and uncertainty estimation:
            <ul>
              <li key="uniqueId22">
                Text Generation and its efficient evaluation. Text style
                transfer, dialogue agents, machine translation.
              </li>
              <li key="uniqueId23">
                Distillation, quantization of deep learning models. Efficient
                implementations of DL-related operations.
              </li>
              <li key="uniqueId24">Active Learning and uncertainty estimation.</li>
            </ul>
            I'm a native speaker of Russian and fluent in English.
          </p>
          <p>
            <a
              class="link"
              href="https://scholar.google.com/citations?hl=en&view_op=list_works&authuser=2&gmla=AJsN-F56coF61cTUGPdchlXACkFRLK3oUxFK0vxY7eAUpugnOUnLBZF5vmVtL4FK3_zRAipkFlA6PUCDHjxL0cKwBFVeVn7g2g&user=kvsOlOQAAAAJ"
            >
              Google Scholar
            </a>{" "}
            |{" "}
            <a class="link" href="https://github.com/Rexhaif">
              Github
            </a>
          </p>
        </div>
      ),
    },
    {
      name: "Ran Zhang",
      image: require("./images/ZhangRan.jpeg"),
      role: "PhD Student",
      email: "ran.zhang@uni-mannheim.de",
      description: (
        <div>
          <p>
            I am a freshly-baked Ph.D. student at University of Mannheim. Before
            that, Humboldt-Universität zu Berlin kindly awards me my master's
            degree in Statistics (yet not kind enough to award me another degree
            in mathematics). I was hired as a GPU burner at data science lab in
            Hertie School of Governance for a year, digging causal languages of
            political texts from the ashes.
          </p>
          <p>
            Now my interests are to keep burning GPUs in Mannheim on
            fascinating topics such as :
            <ul>
              <li key="uniqueId25">Developing metrics to quantify human-unreadable texts.</li>
              <li key="uniqueId26">
                Generating poetry that even Shakespeare could not comprehend.
              </li>
              <li key="uniqueId27">
                Summarising historical texts which ends up as creative writing.
              </li>
            </ul>
            My interests are steadily growing and I am learning my fourth
            language taught by machines apart from 100% Chinese, 80% English,
            and 50% German.
          </p>
        </div>
      ),
    },
    {
      name: "Aida Kostikova",
      image: require("./images/AidaKostikova.jpg"),
      role: "PhD Student",
      email: "aida.kostikova@uni-bielefeld.de",
      description: (
        <div>
          <p>
            Hello! I'm Aida Kostikova, currently doing my Ph.D. at Bielefeld
            University, where I'm focused on the field of Large Language Models
            (LLMs). But before I found myself in this domain, I went on quite a
            journey.
          </p>
          <p>
            I started my professional life as a translator, which sparked my
            interest in how languages work and how they can be understood not
            only by humans but also by machines. My curiosity then led me to
            study Intercultural Communication in Politics and Diplomacy at
            Moscow State University, where I realised the potential of
            interdisciplinary studies and how different fields could provide
            valuable insights to each other.
          </p>
          <p>
            Afterwards, I moved on to the European Master’s in Technologies for
            Translation and Interpreting (EM TTI) Programme at New Bulgarian
            University and Ghent University. It was there that I first
            discovered the broader field of Natural Language Processing (NLP).
            This experience marked a turning point in my journey, steering me
            towards NLP in general, and specifically, Large Language Models.
          </p>
          <p>
            Now, in my Ph.D. journey, I work with LLMs every day, examining
            them, finding errors, and learning about their strengths and
            limitations, and one of the intriguing aspects of my research
            involves the application of these models in the domain of social and
            political sciences. My ultimate goal is to enhance these models,
            striving for better accuracy, reliability, and sustainability as
            they continue to evolve.{" "}
          </p>
          <p>
            Stepping into this Ph.D. has been a thrilling ride, and I am looking
            forward to where this exciting field will take me next.
          </p>
        </div>
      ),
    },
    {
      name: "Yanran Chen",
      image: require("./images/YanranChen.jpg"),
      role: "PhD Student",
      email: "yanran.chen@uni-mannheim.de",
      description: (
        <div>
          <p>
          I'm a first-year PhD student at University of Mannheim. Before that, I earned my bachelor's and master's degrees from TU Darmstadt. 
          I discovered my passion for programming when starting as an electrical engineering student at TU Darmstadt, which prompted my 
          transition to iST—an interdisciplinary major in computer science and electrical engineering—during my Bachelor's. This move paves 
          the way for my specialization in computer science, particularly in NLP, during the master's phase. As someone who struggles with 
          language learning, I've always been fascinated by AI's great power in navigating human language; I hope people like me can truly 
          benefit from AI and NLP technology. 
          </p>
          <p>
          During my time as a student assistant at NLLG, I worked on text generation and evaluation metrics. 
          Now, in my PhD journey, I'm primarily interested in argument generation, focusing on:
          <ul>
              <li key="uniqueId28">Exploring the role of emotions in convincingness of arguments</li>
              <li key="uniqueId29">
              Generating emotion-bias / fallacy free but convincing arguments.
              </li>
            </ul>
          </p>
          <p>
            <a
              class="link"
              href="https://scholar.google.com/citations?user=K0iCb00AAAAJ&hl=en&oi=ao"
            >
              Google Scholar
            </a>{" "}
            |{" "}
            <a class="link" href="https://github.com/cyr19">
              Github
            </a>
          </p>
        </div>
      ),
    },
  ],
  [
    "Secretary",
    {
      name: "Jennifer Graber",
      image: require("./images/dummy.jpg"),
      email: "jennifer.graber@uni-mannheim.de",
      role: "Secretary",
      description: <div>Follows</div>,
    },
  ],
  [
    "Interns",
    {
      name: "Daniil Bergman",
      image: require("./images/dummy.jpg"),
      role: "Intern",
      description: <div>Intern from Finland in 2023</div>,
    },
    {
      name: "Shahin Yousefi",
      image: require("./images/dummy.jpg"),
      role: "Intern",
      description: <div>Intern from Iran in 2023</div>,
    },
    {
      name: "Hoa Nguyen",
      image: require("./images/dummy.jpg"),
      role: "Intern",
      description: <div>Intern from Germany in 2022</div>,
    },
    {
      name: "Ayodele Awokoya",
      image: require("./images/dummy.jpg"),
      role: "Intern",
      description: <div>Intern from Nigeria in 2022</div>,
    },
    {
      name: "Dominik Beese",
      image: require("./images/dummy.jpg"),
      role: "Intern",
      description: <div>Intern from Germany in 2022</div>,
    },
    {
      name: "Görkem Güzeler",
      image: require("./images/dummy.jpg"),
      role: "Intern",
      description: <div>Intern from Finland in 2021</div>,
    },
    {
      name: "Begüm Altunbas",
      image: require("./images/dummy.jpg"),
      role: "Intern",
      description: <div>Intern from Turkey in 2021</div>,
    },
  ],
  [
    "Hiwis",
    {
      name: "Vivian Fresen",
      image: require("./images/dummy.jpg"),
      role: "Hiwi",
      description: <div>Follows</div>,
    },
  ],
];

export default TeamData;
