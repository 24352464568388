import PublicationList from "../components/display/PublicationList";
import PageHeader from "../components/display/PageHeader";
import PublicationsData from "../data/content/PublicationsData";
import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";

function PublicationPage() {
  return (
    <Container className={classes.container}>
      <PageHeader
        headerText="Publications"
        headerImage={require("../data/content/images/publications1.png")}
        headerImageAlt="Publications"
        headerDescription="Selected recent publications."
      />
      <PublicationList publicationData={PublicationsData} />
    </Container>
  );
}

export default PublicationPage;
