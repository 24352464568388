import ThesisItem from './ThesisItem';

function ThesisList(props) {
  return (
    props.thesesData.map((thesis) => (
        <ThesisItem
            type={thesis.type}
            student={thesis.student}
            time={thesis.time}
            title={thesis.title}
            description={thesis.description}
            content={thesis.content}
        />
      ))
  );
}

export default ThesisList;