import Row from "react-bootstrap/Row";
import classes from "./Item.module.css";

function PageHeader(props) {
  return (
    <div>
      <Row className={classes.headerRow}>
        <h1 className={classes.heading}>{props.headerText}</h1>
      </Row>
      <Row className={classes.headerRow}>
        <img
          src={props.headerImage}
          alt={props.headerImageAlt}
          className={classes.headerImage}
        />
      </Row>
      <Row className={classes.headerRow}>
        <p>{props.headerDescription}</p>
      </Row>
    </div>
  );
}

export default PageHeader ;
