import Pdf from "./other/2312.05688.pdf";

const PreprintData = [
  {
    authors: "Ran Zhang, Aida Kostikova, Christoph Leiter, Jonas Belouadi, Daniil Larionov, Yanran Chen, Vivian Fresen, Steffen Eger",
    title: "NLLG Quarterly arXiv Report 09/23: What are the most influential current AI Papers?",
    venue: "Arxiv, 2023",
    paper: Pdf,
    code: "https://nl2g.github.io",
    abstract: (
      <p>Artificial Intelligence (AI) has witnessed rapid growth, especially in the subfields Natural Language Processing (NLP),
        Machine Learning (ML) and Computer Vision (CV). Keeping pace with this rapid progress poses a considerable challenge for
        researchers and professionals in the field. In this arXiv report, the second of its kind, which covers the period from
        January to September 2023, we aim to provide insights and analysis that help navigate these dynamic areas of AI. We accomplish
        this by 1) identifying the top-40 most cited papers from arXiv in the given period, comparing the current top-40 papers
        to the previous report, which covered the period January to June; 2) analyzing dataset characteristics and keyword
        popularity; 3) examining the global sectoral distribution of institutions to reveal differences in engagement across
        geographical areas. Our findings highlight the continued dominance of NLP: while only 16% of all submitted papers have
        NLP as primary category (more than 25% have CV and ML as primary category), 50% of the most cited papers have NLP as
        primary category, 90% of which target LLMs. Additionally, we show that i) the US dominates among both top-40 and top-9k papers,
        followed by China; ii) Europe clearly lags behind and is hardly represented in the top-40 most cited papers; iii) US industry
        is largely overrepresented in the top-40 most influential papers. </p>
    )
  },
];

export default PreprintData;
