import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";
import PageHeader from "../components/display/PageHeader";
import TeachingList from "../components/display/TeachingList";
import ProjectData from "../data/content/ProjectData";


function ProjectsPage() {
  return (
    <Container className={classes.container}>
      <PageHeader
        headerText="Projects"
        headerImage={require("../data/content/images/projects1.png")}
        headerImageAlt="Projects"
        headerDescription="Here you can find information on our latest projects"
      />
      <TeachingList teachingData={ProjectData} />
</Container>
  );
}

export default ProjectsPage;
