import PublicationItem from './PublicationItem';

function PublicationList(props) {
  return (
    props.publicationData.map((publication) => (
        <PublicationItem
            authors={publication.authors}
            title={publication.title}
            venue={publication.venue}
            paper={publication.paper}
            code={publication.code}
            abstract={publication.abstract}
        />
      ))
  );
}

export default PublicationList;