import TeachingItem from './TeachingItem';

function TeachingList(props) {
  return (
    props.teachingData.map((teaching) => (
        <TeachingItem
            title={teaching.title}
            description={teaching.description}
            date={teaching.date}
            image={teaching.image}
            volume={teaching.volume}
        />
      ))
  );
}

export default TeachingList;