import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";
import PageHeader from "../components/display/PageHeader";

function PhilosophyPage() {
  return (
    <Container className={classes.container}>
      <PageHeader
        headerText="Philosophy"
        headerImage={require("../data/content/images/philosophy1.png")}
        headerImageAlt="Team"
        headerDescription=<p>
          Our Group's philosophy follows the <strong>EGER</strong> principle:
        </p>
      />
      <div className={classes.philosophyContent}>
        <ul>
          <li>
            <strong>Excellence</strong>: We always aim for the highest
            publication venues, reworking papers and experiments as often as
            necessary to achieve best outcomes
          </li>
          <li>
            <strong>Guidance</strong>: Achieving best quality (in collaboration
            with students) requires regular guidance (e.g., weekly-biweekly
            meetings)
          </li>
          <li>
            <strong>Emancipation</strong> (a.k.a. Independence):
            Students/employees must have the liberty to explore and find the
            best conditions for their personal working styles. In contrast,
            surveilling students/employees leads to distrust and an unhealthy
            work environment
          </li>
          <li>
            <strong>Recreation/Rewards</strong>: who works hard deserves to
            party hard as well :-). Rewards increase the motivation of
            employees/students and strengthen team spirit.
          </li>
        </ul>
      </div>
    </Container>
  );
}

export default PhilosophyPage;
