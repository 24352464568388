import Pdf from "./other/Eval4NLP_Annotators_MT.pdf";
import Pdf2 from "./other/Eval4NLP_Annotators_Summ.pdf";
import Pdf3 from "./other/ads_liteval.pdf";
import Pdf4 from "./other/argument_annotators_v2.pdf";


const PositionsData = [
  {
    title:
      "Annotators of Argument Convincingness",
    description: (
      <p>
        We are searching for Hiwis to help us annotate arguments from parliamentary debates. See <a href={Pdf4} target="_blank" rel="noreferrer" > PDF</a>.
        <br /><br />
        <strong>What we expect:</strong> You are highly proficient in English and/or German. <br />
        <strong>Deadline:</strong> 20.06.2024<br />
        <strong>Contact:</strong> yanran.chen@uni-mannheim.de
      </p>
    ),
    date: "Deadline: 20.06.2024",
  },
  {
    title:
      "Annotators for Machine Translations",
    description: (
      <p>
        We are searching for annotators of machine translations (MT). See <a href={Pdf3} target="_blank" rel="noreferrer" > PDF</a>.
        <br /><br />
        <strong>What we expect:</strong> You are highly proficient in English and at least one additional language among German, Chinese, Russian, and Japanese (EN + XX);
                OR You are highly proficient in Chinese and German.  Students with translation studies and linguistics backgrounds are highly encouraged to apply. <br />
        <strong>Deadline:</strong> 15.06.2024<br />
        <strong>Contact:</strong>  ran.zhang@uni-mannheim.de
      </p>
    ),
    date: "Deadline: 15.06.2024",
  },
  {
    title:
      "Annotators for Machine Translation and Summarization",
    description: (
      <p>
        We are searching for annotators of machine translations (MT) and small summaries of recent Wikipedia extracts. See PDFs: <br />
        <a href={Pdf} target="_blank" rel="noreferrer" >
          MT
        </a> and <a href={Pdf2} target="_blank" rel="noreferrer" >
          summarization
        </a>
        .
        <br /><br />
        <strong>What we expect:</strong> High proficiency in English (for the summarization task) and additionally one other language (for the machine translation task). Technical understanding is benefitial. <br />
        <strong>Deadline:</strong> 05.08.2023<br />
        <strong>Contact:</strong> christoph.leiter@uni-bielefeld.de
      </p>
    ),
    date: "Deadline: 05.08.2023",
  },
  {
    title:
      "Research position for the AI joint project SAIL - subproject: Understanding the Limitations of Large Language Models (LLMs)",
    description: (
      <p>
        Large Language Models (LLMs) such as BERT have spectacularly pushed the
        state-of-the-art in NLP in the last years. At the same time, multiple
        concerns regarding the quality of LLMs have been brought forward
        recently, e. g., in the context of adversarial attacks. Aim of the
        project is the analysis of LLMs in the context of difficult social
        scientific and political use cases: historical and modern multilingual
        parliamentary debates with their ambiguity and complex indirect speech.
        Besides social science guided data annotation, the project deals with
        learning of (reasoning based) LLMs as well as (NLP and social science
        based) error analysis. The interdisciplinary project is situated in the
        "Natural Language Learning Group" (Dr. Steffen Eger, Faculty of
        Technology) and will be conducted jointly as a cooperation with the
        Semantic Computing Group (Prof. Dr. Philipp Cimiano, especially Dr. Ole
        Pütz). More information can be found{" "}
        <a href="https://uni-bielefeld.hr4you.org/job/view/2120/research-position-for-the-ai-joint-project-sail-subproject-understanding-the-limitations-of-large-la?page_lang=en">
          here
        </a>
        .
        <br /><br />
        <strong>What we expect:</strong> Deep Learning + NLP Skills, Familiarity with Large Language Models<br />
        <strong>What is beneficial:</strong> Interest/Background in Social/Political Science<br />
        <strong>Application documents:</strong> (1) Motivation letter, (2) CV, (3) transcript of grades in <i>one</i> PDF<br />
        <strong>Deadline:</strong> 23.02.2023<br />
        <strong>Contact:</strong> steffen.eger@uni-bielefeld.de, Subject: Wiss22955
      </p>
    ),
    date: "Deadline: 23.02.2023",
  },
];

export default PositionsData;
