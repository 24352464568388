const NewsData = [
  {
    date: "July 2024",
    eventDescription: (
      <div>
        <p>We thank Google for a new grant to use their multimodal LM PaliGemma.</p>
      </div>
    ),
  },
  {
    date: "May 2024",
    eventDescription: (
      <div>
        <p>We welcome our new group member Yanran Chen! She will pursue her PhD working on convincing argument generation.</p>
      </div>
    ),
  },
  {
    date: "April 2024",
    eventDescription: (
      <div>
        <p>One paper accepted at the Journal of "Computational Linguistics" (CL): </p>
        <a class="link" href="https://arxiv.org/abs/2306.12916">
          Cross-lingual Cross-temporal Summarization: Dataset, Models, Evaluation
        </a>
      </div>
    ),
  },
  {
    date: "March 2024",
    eventDescription: (
      <div>
        <p>One paper accepted at the Journal of "Machine Learning with Applications" (MLWA): </p>
        <a class="link" href="https://arxiv.org/abs/2302.13795">
          ChatGPT: A Meta-Analysis after 2.5 Months
        </a>
      </div>
    ),
  },
  {
    date: "March 2024",
    eventDescription: (
      <div>
        <p>One paper accepted at the Journal of "Argumentation & Computation": </p>
        <a class="link" href="https://arxiv.org/abs/2306.04314">
          Cross-Genre Argument Mining: Can Language Models Automatically Fill in Missing Discourse Markers?
        </a>
      </div>
    ),
  },
  {
    date: "February 2024",
    eventDescription: (
      <div>
        <p>One paper accepted at the "Journal of Machine Learning Research" (JMLR): </p>
        <a class="link" href="https://jmlr.org/papers/v25/22-0416.html">
          Towards Explainable Evaluation Metrics for Machine Translation
        </a>
      </div>
    ),
  },
  {
    date: "January 2024",
    eventDescription: (
      <div>
        <p>One paper accepted at ICLR 2024: </p>
        <a class="link" href="https://arxiv.org/abs/2310.00367">
          AutomaTikZ
        </a>
      </div>
    ),
  },
  {
    date: "January 2024",
    eventDescription: (
      <div>
        <p>One findings paper accepted at EACL 2024: </p>
        <a class="link" href="https://arxiv.org/abs/2212.10469">
          BMX
        </a>
      </div>
    ),
  },
  {
    date: "November 2023",
    eventDescription: (
      <div>
        <p>Our group is moving to Mannheim.</p>
      </div>
    ),
  },
  {
    date: "November 2023",
    eventDescription: (
      <div>
        <p>We conducted the Eval4NLP 2023 shared task and published a <a class="link" href="https://arxiv.org/abs/2310.19792">
          overview paper
        </a> at IJCNLP-AACL 2023.</p>
      </div>
    ),
  },
  {
    date: "November 2023",
    eventDescription: (
      <div>
        <p>One findings paper accepted at EMNLP 2023: </p>
        <a class="link" href="https://arxiv.org/abs/2209.09593">
          EffEval
        </a>
      </div>
    ),
  },
  {
    date: "July 2023",
    eventDescription: (
      <div>
        <p>Honourable mention at ACL 2023: </p>
        <a class="link" href="https://aclanthology.org/2023.acl-long.406/">
          ByGPT5
        </a>
      </div>
    ),
  },
  {
    date: "June 2023",
    eventDescription: (
      <div>
        <p>New PhD member Aida Kostikova: Aida will research limitations of large language models in social and political contexts</p>
      </div>
    ),
  },
  {
    date: "May 2023",
    eventDescription: (
      <div>
        <p>Outstanding paper award at EACL 2023: </p>
        <a class="link" href="https://aclanthology.org/2023.eacl-main.27/">
          UScore
        </a>
      </div>
    ),
  },
  {
    date: "May 2023",
    eventDescription: (
      <div>
        <p>Two papers accepted at ACL 2023: </p>
        <a class="link" href="https://aclanthology.org/2023.acl-long.406/">
          ByGPT5
        </a> and <a class="link" href="https://aclanthology.org/2023.findings-acl.434/">
          Trade-Offs Between Fairness and Privacy in Language Modeling
        </a>.
      </div>
    ),
  },
  {
    date: "Jan 2023",
    eventDescription: (
      <div>
        <p>New DFG grant "EMCONA" accepted (joint with Roman Klinger). In EMCONA, we will analyze the interplay between emotions and convincingness in argumentation.</p>
      </div>
    ),
  },
  {
    date: "July 2022",
    eventDescription: (
      <div>
        <p>New BMBF grant "Metrics4NLG" accepted. In Metrics4NLG, we investigate a novel class of evaluation metrics for text generation systems, aiming at their explainability, efficiency, and robustness. "Metrics4NLG" is an interdisciplinary project involving applications in the humanities (e.g., evaluation of poetry generation systems).</p>
      </div>
    ),
  },
  /*{
    date: "June 2022",
    eventDescription: (
      <div>
        <p>One paper accepted at the Journal of Social Computing: </p>
        <a class="link" href="https://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=9832842">
          Measuring Social Solidarity During Crisis: The Role of Design Choices
        </a>
      </div>
    ),
  },
  {
    date: "Sep 2021",
    eventDescription: (
      <div>
        <p>One paper accepted at EMNLP 2021: </p>
        <a class="link" href="https://aclanthology.org/2021.emnlp-main.701.pdf">
          Global Explainability
        </a>
      </div>
    ),
  },
  */
];

export default NewsData;
