import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";

function ContactPage() {
  return (
    <Container className={classes.container}>
      <h1><b>English</b></h1>
      <h2>Legal Notice as required per &sect; 5 TMG</h2>
      <p>
        Christoph Leiter
        <br />
        A3 3
        <br />
        68159 Mannheim
      </p>

      <h2>Contact</h2>
      <p>
        Phone: +49 (0) 6129b5790455
        <br />
        E-Mail: christoph.leiter(at)uni-bielefeld.de
      </p>

      <h1><b>Deutsch</b></h1>
      <h2>Impressum - Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Christoph Leiter
        <br />
        A3 3
        <br />
        68159 Mannheim
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: +49 (0) 6129b5790455
        <br />
        E-Mail: christoph.leiter(at)uni-bielefeld.de
      </p>
    </Container>
  );
}

export default ContactPage;
