import ThesisList from "../components/display/ThesisList";
import ThesesData from "../data/content/ThesesData";
import ThesesDataOpen from "../data/content/ThesesDataOpen";
import PageHeader from "../components/display/PageHeader";
import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";

function ThesesPage() {
  return (
    <Container className={classes.container}>
      <PageHeader
        headerText="Open Thesis Positions"
        headerImage={require("../data/content/images/thesis1.png")}
        headerImageAlt="Theses"
        headerDescription="Current open bachelor- and master-thesis positions of our group,"
      />
      <ThesisList thesesData={ThesesDataOpen} />
      <PageHeader
        headerText="Past Theses"
        headerDescription="Selected recent PhD, Master, and Bachelor theses supervised in the context of NLLG."
      />
      <ThesisList thesesData={ThesesData} />
    </Container>
  );
}

export default ThesesPage;
