const TeachingData = [
  {
    title: "SNLP",
    description: <p>In <a href="https://ekvv.uni-bielefeld.de/sinfo/publ/modul/39160638">Statistical NLP</a>, we cover the NLP era from 1990 to 2010, with a focus on models such as HMMs, CRFs, and MEMs. Held at Bielefeld University.</p>,
    date: "WS2022",
  },
  {
    title: "ELSI",
    description: <p>In the seminar <a href="https://ekvv.uni-bielefeld.de/sinfo/publ/modul/108247040">ELSI</a>, we discuss Ethical, Legal and Social Aspects of data science. Held at Bielefeld University.</p>,
    date: "WS2022",
  },
  {
    title: "DL4NLP",
    description: <p>In the project seminar Deep Learning for NLP, we cover the NLP era from 2011 onwards, with a focus on deep learning models and representations. Held at Bielefeld University. Details are on the <a href="https://github.com/steffeneger/dl4nlp">course github</a>.</p>,
    date: "SS2022",
  },
  {
    title: "DLDH",
    description: <p>In the project seminar Deep Learning and Digital Humanities, we covered problems at the intersection of Deep Learning and Digital Humanities. Held at TU Darmstadt. Details are on the <a href="https://github.com/steffeneger/dldh">course github</a>.</p>,
    date: "WS2019-WS2021",
  },
  {
    title: "MSE",
    description: <p>In the project seminar Meta Science and Evaluation, we covered problems involving the science of science (e.g., citation count prediction) and evaluation (for text generation models). Held at TU Darmstadt. Details are on the <a href="https://github.com/steffeneger/MetaScience">course github</a>.</p>,
    date: "SS2020-SS2021",
  }

];

export default TeachingData;
