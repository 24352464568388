import NLLGNavbar from "./NLLGNavbar";
import classes from "./Layout.module.css";

function Layout(props) {
  return (
    <div>
      <NLLGNavbar logos={props.logos} />
      <main className={classes.main}>{props.children}</main>
      <footer className={classes.footer}>@Copyright NLLG 2023 - All Right Reserved.</footer>
    </div>
  );
}

export default Layout;
