import classes from "./Item.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function PublicationItem(props) {
  return (
    <Row className={classes.row}>
      <Col sm={2} md={2} className={classes.dateColumn}>
        <div className="my-auto">
          <b>{props.venue}</b> <br />
          <b>Authors:</b> {props.authors}
          <br />
          <a href={props.paper}>Paper</a>{props.code ? <a href={props.code}> / Code</a>:  "" }
        </div>
      </Col>
      <Col sm={10} md={10}>
        <h4>{props.title}</h4>
        {props.abstract}
      </Col>
    </Row>
  );
}
export default PublicationItem;
