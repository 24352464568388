import TeamMemberItem from './TeamMemberItem';

function TeamMemberList(props) {
  return (
    props.teamData.map((role) => (
      role.slice(1).map((member) => (
        <TeamMemberItem
            name={member.name}
            image={member.image}
            description={member.description}
            role={member.role}
        />
      ))))
  );
}

export default TeamMemberList;