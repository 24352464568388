import Container from "react-bootstrap/Container";
import classes from "./Page.module.css";
import PageHeader from "../components/display/PageHeader";
import TeachingList from "../components/display/TeachingList";
import PositionsData from "../data/content/PositionsData";


function PositionsPage() {
  return (
    <Container className={classes.container}>
      <PageHeader
        headerText="Open Positions"
        headerImage={require("../data/content/images/positions1.jpg")}
        headerImageAlt="Positions"
        headerDescription="Currently our team has the following open positions:"
      />
      <TeachingList teachingData={PositionsData} />
</Container>
  );
}

export default PositionsPage;