const ThesesData = [
    {
        "type": "Master",
        "student": "Benjamin Hirsch",
        "time": "2022-2023",
        "title": "Diversity in poetry generation",
        "description": <p>Follows</p>
    },
    {
        "type": "Master",
        "student": "Jihed Ouni",
        "time": "2022-2023",
        "title": "Cross-lingual Cross-temporal Summarization",
        "description": <p>Follows</p>
    },
    {
        "type": "Master",
        "student": "Cleo Matzken",
        "time": "2022",
        "title": "Fairness and Privacy",
        "description": <p>Follows</p>
    },
    {
        "type": "PhD",
        "student": "Wei Zhao",
        "time": "2018-2022",
        "title": "On the Principles of Evaluation for Natural Language Generation",
        "description": <p>Follows</p>
    },
    {
        "type": "Bachelor",
        "student": "Zuojun Shi",
        "time": "2021-2022",
        "title": "Factuality in MT evaluation metrics",
        "description": <p>Follows</p>
    },
    {
        "type": "Bachelor",
        "student": "Leon Krause",
        "time": "2021-2022",
        "title": "Syntactic Change Analysis",
        "description": <p>Follows</p>
    },
    {
        "type": "Bachelor",
        "student": "Yichen Xi",
        "time": "2022-2023",
        "title": "Abstract-to-title generation",
        "description": <p>Follows</p>
    },
    {
        "type": "Master",
        "student": "Jens Grünwald",
        "time": "2021-2022",
        "title": "Efficient Evaluation Metrics",
        "description": <p>Follows</p>
    },
    {
        "type": "Master",
        "student": "Chi Viet Vu",
        "time": "2021-2022",
        "title": "Explainable Evaluation Metrics",
        "description": <p>Follows</p>
    },
    {
        "type": "Bachelor",
        "student": "Doan Nam Long Vu",
        "time": "2021-2022",
        "title": "Robust Evaluation Metrics",
        "description": <p>Follows</p>
    },
    {
        "type": "Bachelor",
        "student": "Yanran Chen",
        "time": "2021-2022",
        "title": "Reproducibility for BERT-Based Evaluation Metrics",
        "description": <p>Follows</p>
    }
]

export default ThesesData;
