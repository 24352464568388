import classes from "./Item.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TeamMemberItem(props) {
  return (
    <Row className={classes.row}>
      <Col sm={2} md={2} className={classes.dateColumn}>
        <b>{props.date}</b>
      </Col>
      <Col sm={10} md={10}>
        {props.eventDescription}
      </Col>
    </Row>
  );
}
export default TeamMemberItem;
