import { Route, Routes } from "react-router-dom";

import ContactPage from './pages/ContactPage';
import MainPage from './pages/MainPage';
import PublicationsPage from './pages/PublicationsPage';
import PrePrintPage from './pages/PrePrintPage';
import TeachingPage from './pages/TeachingPage';
import TeamPage from './pages/TeamPage';
import ThesesPage from './pages/ThesesPage';
import ProjectsPage from "./pages/ProjectsPage";
import PhilosophyPage from "./pages/PhilosophyPage";
import PositionsPage from "./pages/PositionsPage";

import Layout from './components/layout/Layout';

function App() {
  const logos = [[require("./data/content/images/logo.png"), "/"],
                 [require("./data/content/images/heisenberg.png"), "https://www.dfg.de/foerderung/programme/einzelfoerderung/heisenberg/"],
                 [require("./data/content/images/BMBF_gefoerdert_2017_en.png"), "https://www.bmbf.de/bmbf/de/home/home_node.html"],
                 [require("./data/content/images/uma_logo_en.png"), "https://www.uni-mannheim.de/"]]

  return (
    <Layout logos={logos}>
      <Routes basename="nl2g">
        <Route path="/" exact element={<MainPage logo={logos[0][0]}/>} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/philosophy" element={<PhilosophyPage />} />
        <Route path="/publications" element={<PublicationsPage />} />
        <Route path="/preprints" element={<PrePrintPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/positions" element={<PositionsPage />} />
        <Route path="/teaching" element={<TeachingPage />} />
        <Route path="/theses" exact element={<ThesesPage />} />
        <Route path="/impressum" element={<ContactPage />} />
      </Routes>
    </Layout>
  );
}

export default App;
