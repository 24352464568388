import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HashLink as Link } from 'react-router-hash-link';

import classes from "../../pages/Page.module.css";
import { default as row_classes } from "./Item.module.css";

function TeamMemberListShort(props) {
  return props.teamData.map((role) => (
    <Row
      className={
        row_classes.teamMini + " justify-content-md-right " + row_classes.row
      }
    >
      <Col md={2}>
        <h3>{role.slice(0, 1)}</h3>
      </Col>
      {role.slice(1).map((member) => (
        <Col md={2} className={classes.bubbleText}>
          <Link to={"/team#" + member.name.replace(/\s/g, '_')}>
            <img
              src={member.image}
              alt="Member"
              className={
                member.role === "Intern" || member.role === "Hiwi" || member.role === "Secretary"
                  ? row_classes.imageSmaller
                  : classes.image}
            />{" "}
            <br />
          </Link>
          <b>{member.name}</b>
        </Col>
      ))}
    </Row>
  ));
}

export default TeamMemberListShort;
