import NewsItem from './NewsItem';

function NewsList(props) {
  return (
    props.newsData.map((news) => (
        <NewsItem
            date={news.date}
            eventDescription={news.eventDescription}
        />
      ))
  );
}

export default NewsList;