import classes from "./Item.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ThesisItem(props) {
  return (
    <Row className={classes.row}>
      <Col sm={2} md={2} className={classes.dateColumn}>
        <b>{props.type}-Thesis</b> <br />
        {props.time ? <div>Timeframe: {props.time} <br /></div>:  <div></div>}
        {props.student ? <div>Author: {props.student} <br /></div>:  <div></div>}
        {props.content ? <div>{props.content} <br /></div>:  <div></div>}
      </Col>
      <Col sm={10} md={10}>
      <h4>{props.title}</h4>
      <div>{props.description}</div>
      </Col>
    </Row>
  );
}
export default ThesisItem;