import classes from "./Item.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { default as row_classes } from "./Item.module.css";


function TeamMemberItem(props) {
  return (
    <div id={props.name.replace(/\s/g, '_')}>
    <Row className={classes.row}>
      <Col sm={5} md={4}>
        <img src={props.image} alt="Member" className={
                props.role === "Intern" || props.role === "Hiwi" || props.role === "Secretary"
                  ? row_classes.imageSmaller
                  : classes.image} />
      </Col>
      <Col sm={7} md={8}>
      <header className="title">
        <h4>
          <span><b>{props.name}</b> / {props.role}</span>
        </h4>
      </header>
      {props.description}
      </Col>
    </Row></div>
  );
}
export default TeamMemberItem;
