import NewsList from "../components/display/NewsList";
import NewsData from "../data/content/NewsData";
import Container from "react-bootstrap/Container";
import TeamData from "../data/content/TeamData";
import PageHeader from "../components/display/PageHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from "./Page.module.css";
import TeamMemberListShort from "../components/display/TeamMemberListShort";

function MainPage(props) {
  return (
    <div>
      <Container className={classes.headingContainer}>
        <Row className="justify-content-md-center">
          <Col md={3} sm={3}>
            <img
              src={props.logo}
              alt="author-pic"
              className={classes.titleImage}
            />
          </Col>
          <Col md={9} sm={9}>
            <h1 className={classes.headingMain}>
              Natural Language Learning Group (NLLG = NL²G)
            </h1>
            <h3 className={classes.subHeadingMain}>
              Squaring Natural Language Processing!
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={11} sm={11}>
            <p>
              The Natural Language Learning Group (NLLG) at University of Mannheim focuses on problems of{" "}
              <b>text generation</b> (in humanities contexts; such as poetry
              generation), <b>evaluation of text generation</b>, as well as
              problems at the intersection of{" "}
              <b>NLP, the digital humanities and social science</b>. The NLLG
              group is headed by{" "}
              <a href="https://steffeneger.github.io/">Steffen Eger</a> and supported by the DFG through a Heisenberg grant and by the BMBF via the research group grant "Metrics4NLG". Current team
              members are:
            </p>
          </Col>
        </Row>
        <TeamMemberListShort teamData={TeamData} />
        <p className={classes.mainText}>
          Follow us on Twitter:{" "}
          <a href="https://twitter.com/NL2Gmannheim">
            https://twitter.com/NL2Gmannheim
          </a>{" "}
          !
        </p>
      </Container>

      <Container className={classes.container}>
        <PageHeader
          headerText="News"
          headerImage={require("../data/content/images/news1.png")}
          headerImageAlt="Projects"
          headerDescription="Latest news of our group."
        />
        <NewsList newsData={NewsData} />
      </Container>
    </div>
  );
}

export default MainPage;
