import classes from "./Item.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TeachingItem(props) {
  return (
    <Row className={classes.row}>
      <Col sm={2} md={2} className={classes.dateColumn}>
        <div>
          <b>{props.date}</b>
          {props.volume ? (
            <div>
              <b>Volume:</b> {props.volume}
            </div>
          ) : (
            ""
          )}
          {props.image ? (
            <div>
              <img
                src={props.image}
                alt="Project"
                className={classes.projImage}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Col>
      <Col sm={10} md={10}>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </Col>
    </Row>
  );
}
export default TeachingItem;
